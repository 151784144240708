import React, { useEffect, useState } from "react";
import DividerCenter from "@atoms/DividerCenter";
import styles from "./join-us.module.scss";
import SelectInput from "@atoms/SelectInput";
import JobItem from "@molecules/JobItem";
import Form from "@molecules/Form";
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next";
import { axios } from "../../../config/axios";

const JoinUs = () => {
  const { t } = useTranslation();
  const { language } = useI18next();

  const [locationSearched, setLocationSearched] = useState("");
  const [timeZoneSearched, setTimeZoneSearched] = useState("");
  const [jobSearched, setJobSearched] = useState("");
  const [jobList, setJobList] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [timeZonesData, setTimeZonesData] = useState([]);
  const [jobsData, setJobsData] = useState([]);  

  useEffect(() => {
    const filteredData = jobList.filter(item => {
      const locationCondition =
        item.location.name === (locationSearched?.value || item.location.name);

      const timeCondition = timeZoneSearched?.value === "All time zones" ? true : (item.time_zone.name === (timeZoneSearched?.value || item.time_zone.name)); //if selected option is "All time zones", it should not filter

      const jobCondition =
        item.job_translations[language]?.name ===
        (jobSearched?.value || item.job_translations[language]?.name);

      return locationCondition && timeCondition && jobCondition;
    });

    const filteredFullRemoteData = jobList.filter(item => {
      const locationCondition =
        item.location.name  ===  "Full remote";

      const timeCondition = timeZoneSearched?.value  ===  "All time zones" ? true : (item.time_zone.name === (timeZoneSearched?.value || item.time_zone.name)); //if selected option is "All time zones", it should not filter

      const jobCondition =
        item.job_translations[language]?.name ===
        (jobSearched?.value || item.job_translations[language]?.name);

      return locationCondition && timeCondition && jobCondition;
    });
    let filteredJobs = [...new Set([...filteredData, ...filteredFullRemoteData])];

    filteredJobs = filteredJobs.filter(f => Object.keys(f.job_translations).length > 0);
    
    setFilteredJobs(filteredJobs);
  }, [locationSearched, timeZoneSearched, jobSearched, jobList, language]);

  const getJobs = async () => {
    try {
      const { data } = await axios.get("/jobs", { params: { _limit: -1 }});
      data.sort((a, b) =>  a.location.name.toLowerCase().localeCompare(b.location.name.toLowerCase()))
      const jobsArr = data.map(el => {
        let job_translations = {};
        el.job_translations.forEach(el => {
          job_translations = { ...job_translations, [el.language]: { ...el } };
        });
        return { ...el, job_translations };
      });
      
      const jobs = jobsArr.map(el => {
        console.log(el.job_translations[language]?.name);
        return {
          value: el.job_translations[language]?.name,
          label: el.job_translations[language]?.name,
        };
      });

      const locations = jobsArr.map(el => {        
        return {
          ...el.location,
          value: el.location.name,
          label: el.location.name,
        };
      });            
      const timezones = jobsArr.map(el => {
        return {
          ...el.time_zone,
          value: el.time_zone.name,
          label: el.time_zone.name,
        };
      });
      jobs.sort((a, b) => a.value?.toLowerCase().localeCompare(b.value?.toLowerCase()))  
      timezones.sort((a, b) => a.value?.toLowerCase().localeCompare(b.value?.toLowerCase()))
      const locationsLabel = t("WORK.LOCATIONSLABEL");
      const jobsLabel = t("WORK.JOBSLABEL");  
      locations.unshift({ value: "", label: locationsLabel });
      jobs.unshift({ value: "", label: jobsLabel });
      setTimeZonesData([...new Set(timezones.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)));
      setLocationData([...new Set(locations.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)));      
      setJobsData([...new Set(jobs.map(o => JSON.stringify(o)))].map(s => JSON.parse(s)));      
      setJobList(jobsArr.sort((a, b) => new Date(b.published_at) - new Date(a.published_at)));
      setFilteredJobs(jobsArr);      
    } catch (error) {
      throw error;
    }
  };  

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <>
      <section className={`${styles.section} text-center`} id='apply'>
        <DividerCenter />

        <h2 className={styles.title}>{t("WORK.JOIN")}</h2>
        <div className={styles.selectContainer}>
          <SelectInput
            data={locationData}
            type={"LOCATION"}
            handleSearch={setLocationSearched}
          />
          <SelectInput
            data={timeZonesData}
            type={"TIME_ZONE"}
            handleSearch={setTimeZoneSearched}
          />
          <SelectInput
            data={jobsData}
            handleSearch={setJobSearched}
            type={"TRABAJO"}
          />
        </div>

        {locationSearched && timeZoneSearched && jobSearched && (
          <p className={styles.jobsDescription}>
            {`${filteredJobs.length} `}
            {filteredJobs.length === 1
              ? `${t("WORK.JOB")}`
              : `${t("WORK.JOBS")}`}{" "}
            <span>{t("WORK.IN")}</span> {locationSearched.label}{" "}
            <span>{t("WORK.AND")}</span> {timeZoneSearched.label}{" "}
            <span>{t("WORK.FOR")}</span> {jobSearched.label}
          </p>
        )}
        <div className={styles.jobList}>
          {filteredJobs.map(el => (
            <JobItem key={el.id} item={el} />
          ))}
        </div>
        <h2
          className={styles.title}
          style={{ maxWidth: 600, margin: "200px auto 0" }}
        >
          {t("WORK.TALENT")}
        </h2>

        <Form />
      </section>
    </>
  );
};

export default JoinUs;
