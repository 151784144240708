import React from "react";
// import { Link } from "gatsby";
import styles from "./job-item.module.scss";
import arrowRight from "../../../assets/svg/arrow_right.svg";
import SuitCase from "../../../assets/svg/Suitcase.svg";
import locationIcon from "../../../assets/svg/Pin.svg";
import Clock from "../../../assets/svg/Clock.svg";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { useTranslation, Link, useI18next } from "gatsby-plugin-react-i18next";
import Divider from "../../../assets/svg/dividerCenter.svg";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw'

const JobItem = ({ item }) => {
  const breakpoints = useBreakpoint();
  const { t } = useTranslation();
  const { language } = useI18next();
  

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <h3 className={styles.headerText}>
          <img src={SuitCase} alt='Suitcase Icon' loading="lazy"/>{" "} 
          {item.job_translations[language]?.name}{" "}
          {!breakpoints.sm && (
            <>
              <img src={locationIcon} alt='Pin Icon' loading="lazy" /> {item.location.name}{" "}
              <img src={Clock} alt='Clock Icon' loading="lazy" /> {item.job_type.type}
            </>
          )}
        </h3>

        {!breakpoints.sm && (
          <Link
            language={language}
            className={styles.headerCTA}
            to={`/work/apply`}
            state={{ item }}
          >
            <img src={arrowRight} alt='Right arrow' loading="lazy"/> {t("WORK.APPLYTO")}
          </Link>
        )}
      </div>
      {breakpoints.sm && (
        <p className={styles.subHeaderTextMobile}>
          {item.location.name}
          <span className={styles.mobileDot}>·</span>
          {item.job_type.type}
        </p>
      )}
      <p className={styles.description}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>
          {item.job_translations[language]?.description}
        </ReactMarkdown>
      </p>
      {breakpoints.sm && (
        <>
          <div className={styles.dividerContainer}>
            <img src={Divider} alt='Linea divisoria'  loading="lazy"/>
          </div>
          <Link
            language={language}
            className={styles.headerCTA}
            to={`/work/apply`}
            state={{ item }}
          >
            {t("WORK.APPLYTO")}{" "}
            <img
              style={{ marginLeft: 20 }}
              src={arrowRight}
              alt='Right arrow'
              loading="lazy"
            />
          </Link>
        </>
      )}
    </div>
  );
};

export default JobItem;
