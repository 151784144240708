import React, { useEffect, useState } from "react";
import Select from "react-select";
import styles from "./select-input.module.scss";
import { useTranslation } from "gatsby-plugin-react-i18next";

const colourStyles = {
  menu: (provided, state) => ({
    ...provided,
    background:
      "linear-gradient(142.44deg, rgba(0, 0, 0, .9) 100%, rgba(0, 0, 0, 0.8) 122.2%)",
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Manrope",
    fontSize: 12,
    letterSpacing: "1px",
  }),
  control: (styles) => ({
    ...styles,
    background:
      "linear-gradient(142.44deg, rgba(255, 255, 255, 0.03) -56.33%, rgba(255, 255, 255, 0.03) 122.2%)",
    border: "none",
    fontFamily: "Manrope",
    letterSpacing: "1px",
    margin: "auto",
    outline: "none",
    height: "100%",
  }),
  input: (styles) => ({
    ...styles,
    color: "white",
    fontFamily: "Manrope",
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: "1px",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "white",
    fontFamily: "Manrope",
    textTransform: "uppercase",
    fontSize: 12,
    letterSpacing: "1px",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "white",
    textTransform: "uppercase",
    fontFamily: "Manrope",
    fontSize: 12,
    letterSpacing: "1px",
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: "none",
  })
};

export default function SelectInput({ data, handleSearch, type }) {
  const [selectedOption, setSelectedOption] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    console.log(data);
    setSelectedOption(data[0]);
  }, [data]);

  useEffect(() => {
    if (selectedOption) handleSearch(selectedOption);
  }, [selectedOption, handleSearch]);

  return (
    <div className={styles.input}>
      <Select
        className={styles.select}
        defaultValue={data[0]}
        onChange={setSelectedOption}
        options={data}
        styles={colourStyles}
        placeholder={`${t("WORK.SELECT")} ${t(`WORK.${type}`)}`}
        isSearchable
        theme={(theme) => ({
          ...theme,
          borderRadius: 4,
          colors: {
            ...theme.colors,
            primary25: "rgba(255, 255, 255, 0.1)",
            primary50: "black",
            primary: "rgba(255, 255, 255, 0.1)",
          },
        })}
      />
    </div>
  );
}
